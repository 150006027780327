.sportFeed {
  .title {
    font-family: lato-black;
    font-size: 15px;
    border-bottom: solid 1px #dd2a30;

    span {
      background-color: white;
      color: rgb(55, 65, 81);
      padding-top: 7px;
      padding-bottom: 7px;

      &.active {
        background-color: #dd2a30;
        color: white;
      }
    }
  }

  > div {
    > div {
      > div {
        margin-left: 18px;
        margin-right: 18px;
      }
      > div:not(:last-of-type) {
        border-bottom: 1px solid rgba(107, 114, 128, 0.3);
      }
    }
  }
}
