.postSizeXS {
  .image {
    height: 150px;
    background-repeat: no-repeat;
  }

  .text {
    .title {
      font-size: 20px;
      font-family: lato-black;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      .novo {
        color: red;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .postSizeXS {
    .image {
      background-size: 100%;
    }

    .text {
      .title {
        font-size: 20px;
      }
    }
  }
}
