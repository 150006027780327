.sideVerticalAdvertisementBarWrapper {
  padding-top: 108px;
  width: 200px;
  right: 0;
}

.sideVerticalAdvertisementBar {
  width: 200px;
  height: 640px;
  position: fixed;
}
