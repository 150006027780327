.wrapper {
  // padding: 3vw 0px 4vh;
  padding: 88px 0px 0px 0px;
  margin-top: -13px;
}

.fullWidthAdBanner {
  img,
  video {
    max-height: 200px;
    margin-bottom: 7px;
  }
}

.advertisement {
  font-size: 9px;
  color: gray;
}

@media only screen and (min-width: 900px) {
  // THIS IS ON BIG SCREENS
  .wrapper {
    padding: 88px 0px 0px 0px;
    margin-top: 0px;
  }

  .fullWidthAdBanner {
    max-width: 1040px;
  }

  .fullWidthAdBanner {
    img,
    video {
      margin-bottom: 20px;
    }
  }
}
