.NewsFeedItem {
  .height-80 {
    height: 80px;
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    border-color: #dd2a30;
    margin-top: 4px;
  }

  .time {
    color: #dd2a30;
    font-family: lato-semibold;
    font-size: 16px;
    margin-top: 1px;
  }

  .title {
    font-family: lato-semibold;
    font-size: 16px;
    line-height: 1.1;
  }

  .content {
    font-family: lato-black;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.125;
  }
}
