.sportScheduleFeed {
  .title {
    font-family: lato-black;
    font-size: 15px;

    span {
      background: #028042;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}
