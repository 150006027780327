.postSizeS {
  padding: 0px;
  width: 635px;

  .ytIcon {
    margin-left: 18px;
    margin-bottom: 3px;
  }

  .image {
    width: 350px;
    height: 150px;
    background-size: cover;
  }

  .text {
    width: 415px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    PostSizeS .subtitle {
      font-size: 16px;
      font-family: lato-semibold;
    }

    .title {
      font-size: 20px;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      font-family: lato-black;
      height: 100%;

      .novo {
        color: red;
      }

      .innerText {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        position: relative;
        padding-left: 18px;
        overflow: hidden;

        .redTriangleContainer {
          position: absolute;
          top: 0;
          left: 0;
          height: 12px;
          width: 18px;
          -ms-transform: translate(-18px, 6px);
          transform: translate(0px, 6px);

          .redTriangle {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }
}

.spaceBetween {
  align-content: space-between;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  height: 80%;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .postSizeS {
    padding: 0 10px;
    width: 100%;

    .image {
      width: 50%;
      width: 200px;
      height: 105px;
    }

    .text {
      width: calc(100% - 50px);

      .subtitle {
        max-height: 35px;
        font-size: 16px;
        line-height: 1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 2px;
        height: 35px;
      }

      .titleWrapper {
        .title {
          font-size: 20px;
          line-height: 1;
          display: flex;
          align-items: center;

          .redTriangleContainer {
            position: absolute;
            top: 0;
            left: 0;
            height: 12px;
            width: 18px;
            -ms-transform: translate(-18px, 6px);
            transform: translate(0px, 4px);

            .redTriangle {
              width: 13px;
              height: 13px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .postSizeS {
    padding: 0 10px;
    width: 100%;

    .image {
      width: 100%;
      width: 200px;
      height: 105px;
    }

    .text {
      width: calc(100%);

      .subtitle {
        max-height: 35px;
        font-size: 16px;
        line-height: 1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 2px;
        height: 35px;
      }

      .titleWrapper {
        .title {
          font-size: 14px;
          line-height: 1;
          display: flex;
          align-items: center;

          .redTriangleContainer {
            position: absolute;
            top: 0;
            left: 0;
            height: 12px;
            width: 18px;
            -ms-transform: translate(-18px, 6px);
            transform: translate(0px, 4px);

            .redTriangle {
              width: 13px;
              height: 13px;
            }
          }
        }
      }
    }
  }
}
