.content {
  max-width: 1080px;
  width: 100%;
}

@media only screen and (max-width: 1365px) {
  .content {
    margin: auto;
  }
}
