.MostReadFeed {
  .title {
    font-family: lato-black;
    font-size: 14px;
    line-height: 1;

    span {
      background: #dd2a30;
      border-radius: 2px;
      padding: 8px;
    }
  }

  > div > a:not(:last-of-type) > div {
    border-bottom: 1px solid gray;
    padding-bottom: 0.75rem;
    border-color: rgba(212, 212, 216, var(--tw-border-opacity));
  }
}
