.matchTitle {
  position: relative;
  font-family: 'lato-black';
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border: 2px solid #dd2a30;
    border-radius: 50%;
    left: -17px;
    top: 8px;
  }
}

.matchInfo {
  span:not(:last-of-type) {
    position: relative;
    margin-right: 20px;
    &::after {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      background-color: red;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      right: -12px;
    }
  }
}
