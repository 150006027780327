.feedAdBanner {
  width: 630px;
  height: 200px;
  background: red;
}

.advertisement {
  font-size: 9px;
  color: gray;
}

@media only screen and (min-width: 900px) {
  .feedAdBanner {
    max-width: 1040px;
    height: 200px;
    background: red;
  }
}
