@import '../../../styles/variables';

.Kairon {
  .KaironText {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
    font-family: 'Asap', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.015em;
    padding-left: 0.65em;
    color: black;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    .KaironText {
      left: 2vw;
      font-size: 3.2vw;
      line-height: 5.5vw;
    }
  }
}

div[class*='PostSizeXS_postSizeXS'] {
  .Kairon {
    .KaironText {
      padding-left: 0.35em;
    }
  }
}

div[class*='PostSizeS_postSizeS'] {
  .Kairon {
    .KaironText {
      padding-left: 0.85em;
    }
  }
}
