.currencyHeader {
  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 30px;

    width: 0;
    height: 0;

    border-top: 15px solid #dd2a30;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}
