@import '../../../styles/variables';

.RightSidePanel {
  width: 100%;
  padding-left: 20px;
}

.verticalPanel {
}

.mtMinus220 {
  margin-top: -220px;
}

@media only screen and (min-width: 1080px) {
  .RightSidePanel {
    width: 405px;
  }
}
