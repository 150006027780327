.postSizeL {
  .image {
    height: 300px !important;
    background-repeat: no-repeat;
    background-size: cover !important;
  }

  .text {
    .subtitle {
      font-family: lato-semibold;
      font-size: 16px;
    }

    .title {
      font-size: 25px;
      font-family: lato-black;
      line-height: 1.5rem;
      .novo {
        color: #ed1c24;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .postSizeL {
    background-size: cover;
    width: 635px;
  }
}
