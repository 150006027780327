.SideAdBanner {
  width: 100%;
  height: 430px;
  height: auto;
}
.advertisement {
  font-size: 9px;
  color: gray;
}

@media only screen and (min-width: 900px) {
  .SideAdBanner {
    width: 385px;
  }
}
